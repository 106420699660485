import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Grid from "@material-ui/core/Grid";

import Certified from "../../assets/home/toronto-plumber.svg";
import Design from "../../assets/home/toronto-plumbers.svg";
import styles from "../../jss/productStyle.js";

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
  },
  section2: {
    padding: "70px 0",
  },
  paragraph2: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "poppins",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
    "@media screen and (min-width:1920px)": {
      marginTop: "10px",
    },
  },
  imageContainer: {
    padding: "60px 60px 0px 60px",
    marginTop: "40px",
    "@media screen and (max-width:500px)": {
      padding: "0px 60px 0px 60px",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "1",
    },
  },
  orderContainer: {
    "@media screen and (max-width:500px)": {
      order: "2",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "2",
    },
  },
});

export default function ProductSection() {
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item lg={3} xs={10} sm={5} md={5}>
          <div align="center" className={classes.imageContainer}>
            <Certified alt="toronto-plumber" height="100%" width="100%" />
          </div>
        </Grid>

        <Grid item xs={10} sm={10} md={10} lg={7}>
          <h2 className={classes.title}>Oakville Plumber</h2>
          <p className={classes.paragraph2}>
            When a plumbing emergency happens, you need help fast. Having no
            running water or a leak somewhere can not only be an inconvenience
            but cause damage to your house. Whether you have a drain that’s
            blocked, a pipe that's leaking or a broken toilet, you’re going to
            want it sorted as soon as possible. Which is where we come in. Go
            Pro Plumbing are leading plumbers in Oakville and can help get your
            water back on track.
          </p>
          <h2 className={classes.title}>We are plumbing experts</h2>
          <p className={classes.paragraph2}>
            We know what a nightmare it can be when you have issues with your plumbing. This is why one of our experienced plumbers is on hand to help. Utilising our extensive knowledge and skills, we will identify the problem and get it sorted. We tackle each job that comes our way with precision, care and skill, ensuring it's done quickly and to the best possible standard.  When it comes to the best plumbers in Oakville, we tick all the boxes.

          </p>
        </Grid>

        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={7}
          className={classes.orderContainer}
        >
          <h2 className={classes.title}> Design & Build</h2>
          <p className={classes.paragraph2}>
            At GoPro Plumbing, we offer a broad range of services including new
            construction plumbing. Our experienced plumbers in Oakville find
            ways to save both time and money during the building process. GoPro
            implements modern plumbing solutions for all of our projects. We
            ensure that our clients receive the most up-to-date building trends
            and methods in the industry.
          </p>
        </Grid>
        <Grid item lg={3} xs={10} sm={5} md={5}>
          <div align="center" className={classes.imageContainer}>
            <Design alt="toronto-plumbers" height="100%" width="100%" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
