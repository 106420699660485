import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

const useStyles = makeStyles({
  ...styles,

  image2: {
    width: "50%",
    height: "400px",
    boxShadow: "0 8px 6px -6px black",
    display: "block",
    borderRadius: "3px",
    position: "relative",
    top: "40px",

    "@media(min-width: 768px) and (max-width: 1024px)": {
      width: "50%",
      height: "350px",
      position: "relative",
      top: "40px",
    },
    "@media(max-width: 500px)": {
      width: "auto",
      height: "210px",
      display: "flex",
    },
  },
});
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div style={{ padding: "60px 0px 0px 0px" }}>
      <div className={classNames(classes.section, classes.sectionDark)}>
        <Grid container justify="center" alignItem="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              RESIDENTIAL SERVICES
            </h2>
          </Grid>
          <Grid item lg={5} xs={10} sm={10} md={7}>
            <p className={classes.paragraph}>
              From a broken toilet to leaky pipes or flooding basements, no
              matter how big or small the problem, it needs to get sorted sooner
              rather than later. Our team are experts in a wide range of areas
              and can help with any problem you might be having. Not only that,
              but we will get the job done fast, causing as little disruption to
              your home and daily life as possible.
            </p>
            <p className={classes.paragraph}>
              We understand that safety and security are your priorities. By
              having a reliable and efficient plumbing system, you also gain
              peace of mind for you and your family’s health.
            </p>
            <p className={classes.paragraph}>
              As well as any unexpected issues that might occur with your
              plumbing, we can also service and diagnose any potential issues
              before they arise. By getting to the root of a problem before it
              escalates, it will save your home (and bank balance!) from any
              real damage.
            </p>
            <p className={classes.paragraph}>
              Contact us for a prompt, professional, and quality service. We
              will fix your plumbing issue whether it is a broken water heater
              or a clogged drain. Our plumbing services in Oakville include:
            </p>
            <ul className={classes.paragraph}>
              <li>New construction</li>
              <li>Fixture upgrades</li>
              <li>Backwater valve installation</li>
              <li>Drain Cleaning & Camera Inspections</li>
              <li>PhynPlus water monitoring</li>
              <li>Kitec removal</li>
              <li>Watermain replacement</li>
              <li>Troubleshooting</li>
            </ul>
          </Grid>
        </Grid>
        <Grid container justify="center" alignItem="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              COMMERCIAL PROJECTS
            </h2>
          </Grid>
          <Grid item lg={5} xs={10} sm={10} md={7}>
            <p className={classes.paragraph}>
              We know that when it comes to your home, you don’t want to
              compromise on safety, but you also don’t want to spend a fortune.
              We believe you can have both and shouldn’t have to be put out of
              pocket to fix your plumbing. Not only do we charge a fair rate,
              but we can also help you identify and fix smaller issues before
              they become big problems. By fixing small leaks and issues, it can
              save your home from damage and your water bill from taking a huge
              hit too.
            </p>
            <p className={classes.paragraph}>
              There’s a reason we’re counted on as a top plumber in Oakville and
              that’s thanks to our 5 star service each and every time. We’ve
              been helping locals for many years, always giving the same level
              of commitment and expertise to fix their plumbing as soon as it
              needs it.
            </p>
            <p className={classes.paragraph}>
              There’s a reason we’re counted on as a top plumber in Oakville and
              that’s thanks to our 5 star service each and every time. We’ve
              been helping locals for many years, always giving the same level
              of commitment and expertise to fix their plumbing as soon as it
              needs it.
            </p>
            <p className={classes.paragraph}>
              We’re the plumbers you can trust, time after time. We will put you
              first and can get to the root of all your plumbing issues. Get in
              touch with us today and find out how we can help.
            </p>
            <ul className={classes.paragraph}>
              <li>Riser, watermain, and kitec replacement</li>
              <li>Drain Cleaning & Inspections</li>
              <li>Fixture upgrades</li>
              <li>Troubleshooting</li>
              <li>PhynPlus water monitoring</li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
