import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

const useStyles = makeStyles({
  ...styles,
  imageInfo5: {
    width: "100%",
    height: "300px",
    boxShadow: "0 8px 6px -6px black",
    display: "block",
    borderRadius: "3px",
    position: "relative",
    top: "120px",

    "@media(min-width: 768px) and (max-width: 1024px)": {
      width: "100%",
      height: "310px",
      position: "relative",
      top: "40px",
    },
    "@media(max-width: 500px)": {
      maxWidth: "100%",
      maxHeight: "210px",
      display: "flex",
      marginLeft: "30px",
    },
  },
});
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section2)}>
      <div>
        <Grid container>
          <Grid item lg={6} xs={12} sm={10} md={7}>
            <div className={classes.containerLeak}>
              <Grid item lg={12} xs={12} sm={10} md={12}>
                <h2 className={classes.title3}>
                  ADVANCED LEAK DETECTION TECHNOLOGY
                </h2>
              </Grid>
              <Grid item lg={10} xs={10} sm={10} md={12}>
                <p className={classes.paragraph}>
                  At GoPro Plumbing, we are proud to offer the installation of
                  PhynPlus to our clients. PhynPlus is an advanced water
                  monitoring system that uses pressure wave analysis to detect
                  issues within the plumbing system.
                </p>
                <p className={classes.paragraph}>
                  When it detects an issue, it instantly alerts property owners
                  through SMS and notification. The device is compatible with
                  PhynApp, Amazon Alexa, and Google Assistant. PhynPlus also
                  allows owners to keep track of their water spending by
                  offering statistics for daily, weekly, and monthly water
                  usage.
                </p>
                <p className={classes.paragraph}>
                  GoPro Plumbing is not your usual plumbing company! We start
                  and end our projects on time and budget, providing quality
                  workmanship while following strict health and safety measures.
                  However, we also make sure that our clients are updated with
                  the latest trends and innovations in the industry.
                </p>
                <p className={classes.paragraph}>
                  Why use old methods to maintain and restore your plumbing
                  system? Choose as your reliable plumber in Brampton and gain
                  access to modern solutions to secure the essential parts of
                  your property.
                </p>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={5} xs={12} sm={10} md={7}>
            <Grid
              container
              justify="right"
              direction="column"
              alignItem="right"
              alignContent="right"
            >
              <Grid item lg={10} xs={10} sm={12} md={12}>
                <div>
                  <iframe
                    className={classes.imageInfo5}
                    src="https://www.youtube.com/embed/_1GnTnaxS1I"
                    frameBorder="0"
                    allow="encrypted-media; gyroscope;"
                    allowFullScreen
                    title="Leak detection system"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
